import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { VideoWrapper } from "./Video.styles";

export interface VideoProps {
  vidId: string;
  rounded?: boolean;
  shadows?: boolean;
}

export function Video({
  vidId,
  rounded = true,
  shadows = true,
  ...props
}: VideoProps) {
  const [allowTracking, setAllowTracking] = useState(false);
  const consent = Cookies.get("cookieconsent_status_v2");

  useEffect(() => {
    if (consent) {
      const consentObj = JSON.parse(consent);
      setAllowTracking(consentObj.allowTrackingCookies);
    } else {
      setAllowTracking(false);
    }
  }, []);

  return (
    <VideoWrapper $rounded={rounded} $shadows={shadows} {...props}>
      <iframe
        id={vidId}
        className="vimeo--video"
        src={`https://player.vimeo.com/video/${vidId}?dnt=${allowTracking}`}
        width="640"
        height="360"
        frameBorder="0"
        allow="fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe>
    </VideoWrapper>
  );
}
