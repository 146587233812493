import React from "react";
import styled from "styled-components";
import { useIntersectionObserver } from "usehooks-ts";
import { useRef } from "react";

export const Wrapper = styled.div`
  height: 200vh;
  position: relative;
`;

export const PositionTracker = styled.div`
  top: 15vh;
  height: 70vh;
  width: 100%;
  position: absolute;
`;

export const Container = styled.div`
  min-height: calc(100vh - ${({ theme }) => theme.header.height});
  position: sticky;
  top: ${({ theme }) => theme.header.height};
`;

export const SlideImg = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 0.3s opacity;
`;

interface CopyContainerProps {
  displayedOver: "black-white" | "white-black";
}

export const CopyContainer = styled.div<CopyContainerProps>`
  box-sizing: border-box;
  min-height: calc(100vh - ${({ theme }) => theme.header.height});
  width: 75%;
  background: ${({ displayedOver }) =>
    displayedOver === "black-white"
      ? "rgba(255, 255, 255, 0.8)"
      : "rgba(255, 255, 255, 0.9)"};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  transition: transform 0.2s ease-in-out;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 50%;
  }

  @media (hover: hover) {
    transition: none;
  }
`;

export const Copy = styled.div`
  max-width: 26rem;
  strong > span {
    color: #d716eb;
  }
  h2 {
    font-weight: 300;
    font-size: 2rem;
    line-height: 2.5rem;
  }
`;
