import React from "react";
import { Widget, PopupButton, SliderButton } from "@typeform/embed-react";
import { CustomButton } from "../../Buttons/Button";
import { StyledContainer } from "./Typeform.styles";
import { ButtonColour, TextAlignment } from "../../../_shared";
import { ButtonWrapper } from "../../Buttons";
import { Padding } from "../../Container/Container";
import { useTheme } from "styled-components";

export interface PureStandardTypeformProps {
  fullWidth: boolean;
  $id: string;
  height?: number;
  padding?: Padding;
}

export function PureStandardTypeform({
  fullWidth = false,
  $id,
  height = 500,
  padding = Padding.both,
  ...props
}: PureStandardTypeformProps) {
  return (
    <StyledContainer
      fullWidth={fullWidth}
      $align={TextAlignment.center}
      padding={padding}
      {...props}
    >
      <Widget
        id={$id}
        style={{
          width: "100%",
          height: `${height}px`,
        }}
        {...props}
      />
    </StyledContainer>
  );
}

export interface PureFullPageTypeformProps {
  $id: string;
  belowHeader?: boolean;
}

export function PureFullPageTypeform({
  $id,
  belowHeader = false,
  ...props
}: PureFullPageTypeformProps) {
  const theme = useTheme();
  const height = belowHeader ? `calc(100vh - ${theme.header.height})` : "100vh";
  return (
    <StyledContainer
      fullWidth={true}
      padding={Padding.none}
      $align={TextAlignment.center}
      {...props}
    >
      <Widget
        id={$id}
        style={{
          width: "100%",
          height: height,
        }}
        {...props}
      />
    </StyledContainer>
  );
}

export interface PurePopupTypeformProps {
  fullWidth: boolean;
  content?: JSX.Element | React.ReactNode | string;
  $id: string;
  buttonTitle?: string;
  buttonType?: ButtonColour;
}

export function PurePopupTypeform({
  fullWidth = false,
  content,
  $id,
  buttonTitle,
  buttonType = "primary",
  ...props
}: PurePopupTypeformProps) {
  return (
    <StyledContainer
      fullWidth={fullWidth}
      $align={TextAlignment.center}
      {...props}
    >
      {content && <>{content}</>}
      <PopupButton id={$id} as="a" {...props}>
        <ButtonWrapper>
          <CustomButton $color={buttonType}>{buttonTitle}</CustomButton>
        </ButtonWrapper>
      </PopupButton>
    </StyledContainer>
  );
}

export interface PureSlideTypeformProps extends PurePopupTypeformProps {}

export function PureSlideTypeform({
  fullWidth = false,
  content,
  $id,
  buttonTitle,
  buttonType = "primary",
  ...props
}: PureSlideTypeformProps) {
  return (
    <StyledContainer
      fullWidth={fullWidth}
      $align={TextAlignment.center}
      {...props}
    >
      {content && <>{content}</>}
      <SliderButton id={$id} as="a" {...props}>
        <ButtonWrapper>
          <CustomButton $color={buttonType}>{buttonTitle}</CustomButton>
        </ButtonWrapper>
      </SliderButton>
    </StyledContainer>
  );
}
