import React from "react";
import styled from "styled-components";

export enum Padding {
  horizontal = "horizontal",
  vertical = "vertical",
  both = "both",
  none = "none",
  top = "top",
}

interface BaseContainerProps {
  fullWidth?: boolean;
  padding?: Padding;
}

const handlePaddingMobile = (padding: Padding) => {
  switch (padding) {
    case Padding.horizontal:
      return "0 1.75rem";
    case Padding.vertical:
      return "1.875rem 0";
    case Padding.both:
      return "1.875rem 1.75rem";
    case Padding.top:
      return "1.875rem 0";
    default:
      return "0";
  }
};

const handlePaddingTablet = (padding: Padding) => {
  switch (padding) {
    case Padding.horizontal:
      return "0 3.5rem";
    case Padding.vertical:
      return "2.5rem 0";
    case Padding.both:
      return "2.5rem 3.5rem";
    default:
      return "0";
  }
};

const handlePaddingDesktop = (padding: Padding) => {
  switch (padding) {
    case Padding.horizontal:
      return "0 5.5rem";
    case Padding.vertical:
      return "3rem 0";
    case Padding.top:
      return "3rem 0 0 0";
    case Padding.both:
      return "3rem 5.5rem";
    default:
      return "0";
  }
};

export const Container = styled.section<BaseContainerProps>`
  width: 100%;

  padding: ${({ padding }) =>
    handlePaddingMobile(padding ? padding : Padding.both)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding: ${({ padding }) =>
      handlePaddingTablet(padding ? padding : Padding.both)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: 0 auto;
    max-width: ${({ theme, fullWidth }) =>
      fullWidth ? "100%" : theme.grid.maxWidth};
    padding: ${({ padding }) =>
      handlePaddingDesktop(padding ? padding : Padding.both)};
  }
`;

Container.defaultProps = {
  fullWidth: false,
  padding: Padding.both,
};
