import React from "react";
import { graphql } from "gatsby";
import { Slugify } from "../../../helpers/slugify";

/**
 * @typedef {import("@prismicio/client").Content.DeepLinkSlice} DeepLinkSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<DeepLinkSlice>} DeepLinkProps
 * @param { DeepLinkProps }
 */

export interface DeepLinkProps {
  slice: any;
}

const DeepLink = ({ slice }: DeepLinkProps) => {
  const sectionId = Slugify(slice.primary.link_id || "");
  return <div id={sectionId}></div>;
};

export default DeepLink;

export const query = graphql`
  fragment DeepLinkDefault on PrismicDeepLinkDefault {
    id
    slice_label
    slice_type
    variation
    primary {
      link_id
    }
  }
`;
