import React from "react";
import { FeaturedVideoContainer } from "./SliceFeaturedVideo.styles";
import { Video } from "../../Video/Video";

export interface PureFeaturedVideoProps {
  vidId: string;
  rounded?: boolean;
  shadows?: boolean;
  fullWidth?: boolean;
}

export function PureFeaturedVideo({
  vidId,
  rounded = true,
  shadows = true,
  fullWidth = false,
  ...props
}: PureFeaturedVideoProps) {
  return (
    <FeaturedVideoContainer fullWidth={fullWidth} {...props}>
      <Video vidId={vidId} shadows={shadows} rounded={rounded} />
    </FeaturedVideoContainer>
  );
}
