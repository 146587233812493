import React from "react";
import { graphql } from "gatsby";
import { PureColumnsText } from "../../design-system/Slices/SliceColumnsText/ColumnsText/ColumnsText";
import { PureBoxedColumns } from "../../design-system/Slices/SliceColumnsText/BoxedColumns/BoxedColumns";
import SliceFooterActions from "../SliceFooterActions";
import { RiskWarningAndDisclosures } from "../../design-system/Slices/Helpers/RiskWarningAndDisclosures";
import CustomRichText from "../../CustomRichText";

/**
 * @typedef {import("@prismicio/client").Content.ThreeColumnTextSlice} ThreeColumnTextSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<ThreeColumnTextSlice>} ThreeColumnTextProps
 * @param { ThreeColumnTextProps }
 */

export interface ColumnTextProps {
  slice: any;
  context?: any;
}

const ColumnText = ({ slice, context }: ColumnTextProps) => {
  const variant = slice.variation;

  if (variant === "boxed") {
    const hasFooterOptions = slice.primary.cta_options.document || false;
    const actions =
      hasFooterOptions &&
      slice.primary.cta_options.document.data.slices.length > 0 ? (
        <SliceFooterActions
          slice={slice.primary.cta_options.document.data.slices[0]}
          align={"center"}
          context={context}
        />
      ) : null;
    const boxes =
      slice.items.length > 0 &&
      slice.items.map((box: any, i: number) => (
        <CustomRichText
          key={i}
          field={box.boxed_content.richText}
          context={context}
        />
      ));

    const boxedHeader = slice.primary.content ? (
      <CustomRichText
        field={slice.primary.content.richText}
        context={context}
      />
    ) : (
      ""
    );

    return (
      <PureBoxedColumns
        content={boxedHeader}
        $vAlign={slice.primary.box_content_alignment}
        actions={actions}
        boxes={boxes}
      />
    );
  }

  const header = slice.primary.header ? (
    <CustomRichText field={slice.primary.header.richText} context={context} />
  ) : (
    ""
  );

  const content =
    slice.items.length > 0 &&
    slice.items.map((column: any, i: number) => {
      const hasFooterOptions = column.cta_options?.document || false;
      const actions =
        hasFooterOptions &&
        column.cta_options.document.data.slices.length > 0 ? (
          <SliceFooterActions
            slice={column.cta_options.document.data.slices[0]}
            align={"center"}
            context={context}
          />
        ) : null;

      return (
        <div key={i}>
          {column.column_content && (
            <CustomRichText
              field={column.column_content.richText}
              context={context}
            />
          )}
          {actions}
          <RiskWarningAndDisclosures
            riskWarning={column.risk_warning}
            content={column.below_cta_content?.richText || null}
            context={context}
          />
        </div>
      );
    });

  return (
    <PureColumnsText
      dividers={slice.primary.columns_dividers ? true : false}
      isWide={slice.primary.header_size === "wide" ? true : false}
      header={header}
      columns={content}
      columnsTextAlignment={slice.primary.columns_text_alignment}
    />
  );
};

export default ColumnText;

export const query = graphql`
  fragment ThreeColumnTextDefault on PrismicThreeColumnTextDefault {
    id
    slice_label
    slice_type
    variation
    items {
      column_content {
        richText
      }
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
      risk_warning
      below_cta_content {
        richText
      }
    }
    primary {
      header_size
      header {
        richText
      }
      columns_text_alignment
      columns_dividers
    }
  }
  fragment ThreeColumnTextBoxed on PrismicThreeColumnTextBoxed {
    id
    slice_label
    slice_type
    variation
    primary {
      box_content_alignment
      content {
        richText
      }
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
    }
    items {
      boxed_content {
        richText
      }
    }
  }
`;
