import styled from "styled-components";

export interface VideoWrapperProps {
  $rounded: boolean;
  $shadows: boolean;
}

export const VideoWrapper = styled.div<VideoWrapperProps>`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  box-shadow: ${({ $shadows, theme }) =>
    $shadows ? theme.shadows.extraSmallNeumorphic : "none"};
  border-radius: ${({ $rounded }) => ($rounded ? "12px" : "0")};
  overflow: hidden;
  margin-top: 2rem;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 3.5rem;
    border-radius: ${({ $rounded }) => ($rounded ? "20px" : "0")};
  }

  iframe.vimeo--video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
